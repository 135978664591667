<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Processes</h1>
        </v-col>

        <v-col class="pl-12">
          <v-text-field
            label="Search Processes"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.processDialog.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Process</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Processes</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="grid-row">
            <v-simple-table>
              <thead>
                <tr>
                  <th
                    v-for="header in tableHeaders"
                    :key="header.text"
                    :class="header.align === 'right' ? 'text-right' : ''"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <draggable v-model="processes" tag="tbody">
                <tr v-for="(process, index) in processes" :key="index">
                  <td>
                    <v-icon small class="page__grab-icon">mdi-arrow-all</v-icon>
                  </td>

                  <td @click="$refs.details.openForm(process)">
                    {{ process.title }}
                  </td>
                  <td class="text-right">
                    <v-btn
                      x-small
                      depressed
                      class="mr-2"
                      color="green lighten-4 green--text"
                      @click="$refs.details.openForm(process)"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>

                    <v-btn
                      bottom
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      @click="openDelete(process)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </v-simple-table>
          </div>
        </v-col>
        <v-col cols="6">
          <Details ref="details" />
        </v-col>
      </v-row>
    </v-container>

    <ProcessDialog ref="processDialog" />

    <v-dialog v-model="deleteProcess.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Process</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteProcess.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Details from "./components/Details.vue";
import ProcessDialog from "./components/ProcessDialog.vue";
import Draggable from "vuedraggable";
import $ from "jquery";

export default {
  components: {
    Details,
    ProcessDialog,
    Draggable,
  },

  data() {
    return {
      deleteProcess: {
        dialog: false,
        process: {},
        loading: false,
      },

      searchTerm: "",
      breadcrumbs: [
        {
          text: "Processes",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],

      tableHeaders: [
        { text: "", sortable: false },
        { text: "Title", value: "title", sortable: false },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  mounted: function () {
    var stickitHeight = $(".stickthis").height() + "px";
    var stickySidebarToTop = $(".stickthis").offset().top;
    $(window).scroll(function () {
      var windowToTop = $(window).scrollTop();
      if (windowToTop + 50 > stickySidebarToTop) {
        $(".stickthis").css({
          position: "sticky",
          top: "70px",
          height: stickitHeight,
          width: "100%",
        });
      } else {
        $(".stickthis").removeAttr("style");
      }
    });
  },

  computed: {
    processes: {
      get() {
        let processes = this.$store.getters["sbpm/processesStore/all"];

        if (this.searchTerm !== "") {
          processes = processes.filter((p) => {
            const title = p.title.toLowerCase();

            const searchTerm = this.searchTerm.toLowerCase();

            return title.includes(searchTerm);
          });
        }

        return processes;
      },

      set(processes) {
        const displayOrder = processes.map((p) => {
          return p.id;
        });
        const appId = this.$route.params.id;

        this.$store.commit("showHideLoader", true);
        this.$store
          .dispatch("sbpm/processesStore/updateProcessesDisplayOrder", {
            appId,
            displayOrder,
          })
          .then(() => this.$store.commit("showHideLoader", false))
          .catch(() => this.$store.commit("showHideLoader", false));
      },
    },
  },

  methods: {
    openDelete(process) {
      this.deleteProcess.process = process;
      this.deleteProcess.dialog = true;
    },

    resetDelete() {
      this.deleteProcess.dialog = false;
      this.deleteProcess.process = {};
      this.deleteProcess.loading = false;
    },

    saveDelete() {
      this.deleteProcess.loading = true;

      this.$store
        .dispatch("sbpm/processesStore/deleteProcess", {
          appId: this.$route.params.id,
          processId: this.deleteProcess.process.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteProcess.loading = false;
        });
    },
  },
};
</script>
