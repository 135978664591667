<template>
  <div class="sidebar stickthis">
    <v-card>
      <v-card-title
        v-if="dialog"
        class="d-flex justify-start align-center headline grey lighten-4"
      >
        <div class="flex-grow-1">Process Details</div>

        <v-btn
          class="accent"
          text
          type="submit"
          :loading="loading"
          form="process-form"
          >Save</v-btn
        >
      </v-card-title>

      <v-card-text class="">
        <div v-if="dialog">
          <v-form @submit.prevent="save" method="post" id="process-form">
            <v-simple-table class="mb-5 no-hover">
              <tbody>
                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Title*</label><br /><br />
                    <v-text-field
                      v-model="fields.title"
                      outlined
                      background-color="white"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Description</label><br /><br />
                    <tiptap v-model="fields.description" class="mb-4" />
                  </td>
                </tr>
                <tr>
                  <td>Created date</td>
                  <td>{{ formatDateTime(process.created_at, true) }}</td>
                </tr>
                <tr v-if="process.user">
                  <td>Created by</td>
                  <td>{{ process.user.full_name }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
        <div v-else>Select a process to see information</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
//import ProcessDialog from "./ProcessDialog.vue";
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
    // ProcessDialog,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      // process: {},
      fields: {
        name: null,
      },
      errors: {},
    };
  },

  computed: {
    process() {
      return this.$store.getters["sbpm/processesStore/get"];
    },
  },

  methods: {
    openForm(process) {
      const appId = this.$route.params.id;
      const processId = process.id;

      this.isEditing = true;

      this.$store.dispatch("sbpm/processesStore/loadProcess", {
        appId,
        processId,
      });

      this.fields.title = process.title;
      this.fields.description = process.description;

      this.dialog = true;
    },
    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.processId = this.process.id;
      }

      this.$store
        .dispatch("sbpm/processesStore/saveProcess", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      //  this.dialog = false;
      this.loading = false;
      //  this.isEditing = false;
      // this.fields = {
      //   title: null,
      //   description: null,
      // };
    },
  },
};
</script>

<style scoped>
.no-hover tr:hover {
  background: transparent !important;
}
</style>
