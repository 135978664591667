<template>
  <div>
    <v-dialog
      v-model="dialogAdd"
      scrollable
      max-width="600"
      @click:outside="reset"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ isEditing ? "Edit" : "Add" }} Process</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
          <v-form @submit.prevent="save" method="post" id="processSbpm-form">
            <v-text-field
              label="Title*"
              v-model="fields.title"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('title')"
              :error-messages="errors['title']"
            ></v-text-field>

            <tiptap
              v-model="fields.description"
              label="Description"
              class="mb-8"
            />
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="reset()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="processSbpm-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },

  data() {
    return {
      dialogAdd: false,
      loading: false,
      isEditing: false,
      process: {},
      fields: {
        title: null,
        description: null,
      },
      errors: {},
    };
  },

  methods: {
    openForm(process = null) {
      if (process !== null) {
        const appId = this.$route.params.id;
        const processId = process.id;

        this.$store.dispatch("sbpm/processesStore/loadProcess", {
          appId,
          processId,
        });

        this.process = process;
        this.isEditing = true;

        this.fields.title = process.title;
        this.fields.description = process.description;
      }

      this.dialogAdd = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.processId = this.process.id;
      }

      this.$store
        .dispatch("sbpm/processesStore/saveProcess", payload)
        .then(() => {
          // this.$refs.details.openForm(resp.data.process);
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialogAdd = false;
      this.loading = false;
      this.isEditing = false;
      this.process = {};
      this.fields = {
        title: null,
        description: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
